import { TRPCClientError } from "@trpc/client";

import { MISSING_FULL_ACCESS_ROLE } from "@offline/constants/errors";

export default function isMissingFullAccessRoleError(error: unknown) {
  return (
    error instanceof TRPCClientError &&
    error.message === MISSING_FULL_ACCESS_ROLE
  );
}
