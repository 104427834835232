import Cookies from "js-cookie";

import { CookieName } from "@offline/constants/enums";

import openNewTab from "./openNewTab";

export default function goToBillingPaymentLinkUrl(
  checkoutUrl: string,
  newTabBlockedMessage: string,
): "new-tab" | "redirect" {
  if (checkoutUrl.includes("/admin/v2")) {
    // Remove cookie that forces pdv to set the PDV store language due to missing NEXT_LOCALE cookie on MercadoPago Redirect
    Cookies.remove(CookieName.MATCHED_LOCALE);
    window.location.href = checkoutUrl;

    return "redirect";
  }

  openNewTab(checkoutUrl, newTabBlockedMessage);

  return "new-tab";
}
