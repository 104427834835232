import { type ComponentProps } from "react";
import { useLocale, useTranslations } from "next-intl";

import CartItemConflictModal from "~/components/CartItemConflictModal";
import { ProductItemWithConflict } from "~/components";
import { getProductName, getVariantImageUrl, getVariantNames } from "~/helpers";
import getLocationStock from "~/helpers/products/getLocationStock";
import { type CartItem } from "~/hooks";
import useSelectedLocation from "~/hooks/useSelectedLocation";

type CartItemConflictModalProps = ComponentProps<typeof CartItemConflictModal>;

type Props = {
  items: CartItem[];
  error: boolean;
} & Omit<
  CartItemConflictModalProps,
  | "children"
  | "title"
  | "description"
  | "dismissButtonText"
  | "confirmButtonText"
  | "renderCartItem"
>;

export default function InsufficientStockInCartModal(props: Props) {
  const t = useTranslations("widgets.insufficient-stock-in-cart-modal");
  const locale = useLocale();
  const { fulfillmentLocation } = useSelectedLocation();

  return (
    <CartItemConflictModal
      {...props}
      title={t("title")}
      description={t("description")}
      errorTitle={t("error.title")}
      errorText={t("error.description")}
      dismissButtonText={t("editCartButton")}
      confirmButtonText={t("confirmButton")}
    >
      {props.items.map(({ product, variant, quantity }) => {
        const productName = getProductName(product, locale);
        const variantName = getVariantNames(variant, locale);
        const imageUrl = getVariantImageUrl(product, variant);
        const stock = getLocationStock(variant, fulfillmentLocation.id);

        const missingUnits = quantity - stock;

        return (
          <ProductItemWithConflict
            key={variant.id}
            productName={productName}
            variantName={variantName}
            imageUrl={imageUrl}
            conflictText={t("missingUnits", { units: missingUnits })}
          />
        );
      })}
    </CartItemConflictModal>
  );
}
