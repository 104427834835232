"use client";

import React from "react";
import { Radio as NimbusRadio, type RadioProps } from "@nimbus-ds/components";

import { NIMBUS_RADIO_ID } from "../../tailwind.config";

export default function Radio(props: RadioProps) {
  return <NimbusRadio {...props} data-override-id={NIMBUS_RADIO_ID} />;
}
