"use client";

import { useCallback, type ReactNode } from "react";
import { CloseIcon } from "@nimbus-ds/icons";
import clsx from "clsx";
import { differenceInCalendarDays } from "date-fns";
import format from "date-fns/format";
import { size } from "lodash";
import ms from "ms";
import { useTranslations } from "next-intl";
import useLocalStorageState from "use-local-storage-state";

import {
  DISPLAY_WELCOME_BANNER_FREE_TRIAL_PERIOD,
  SUBSCRIPTION_DAYS_UNTIL_EXPIRATION_WARNING,
} from "@offline/constants/subscription";

import { trackAmplitudeEvent, trackAppcuesEvent } from "~/helpers/analytics";
import getBillingCheckoutCallbackUrl from "~/helpers/getBillingCheckoutCallbackUrl";
import goToBillingPaymentLinkUrl from "~/helpers/goToBillingPaymentLinkUrl";
import { useCanPerformSecuredApiCalls } from "~/hooks";
import { useRouter } from "~/navigation";
import { appTRPC } from "~/providers/TRPCProvider";

export default function SubscriptionBannerWidget() {
  const t = useTranslations("widgets.subscription-banner");
  const router = useRouter();
  const canPerformSecuredApiCalls = useCanPerformSecuredApiCalls();

  const { data: subscriptionSummary } =
    appTRPC.planSubscriptions.getSummary.useQuery(undefined, {
      enabled: canPerformSecuredApiCalls,
      gcTime: Infinity,
      staleTime: ms("5 minutes"),
    });

  const { mutateAsync: generatePaymentLink } =
    appTRPC.planSubscriptions.payNextPeriod.useMutation();

  const { mutateAsync: retryCurrentPeriodPayment } =
    appTRPC.planSubscriptions.retryCurrentPeriodPayment.useMutation();

  const { subscription, latestChargeStatus, automaticDebitEnabled } =
    subscriptionSummary ?? {};
  const { planPrices } = subscription?.plan ?? {};

  const hasPendingPayment =
    !!latestChargeStatus && latestChargeStatus !== "PAID";
  const paymentFailed =
    latestChargeStatus === "FAILED" || latestChargeStatus === "CANCELLED";

  const handlePaySubscription = useCallback(async () => {
    if (paymentFailed) {
      router.push("/account");
      return;
    }

    if (size(planPrices) > 1 && !hasPendingPayment) {
      router.push("/account/payment-period");
      return;
    }

    const planPriceId = planPrices?.[0]?.id;

    if (!planPriceId) {
      return;
    }

    let paymentLink;

    const callbackUrl = getBillingCheckoutCallbackUrl();
    if (hasPendingPayment) {
      ({ paymentLink } = await retryCurrentPeriodPayment({
        callbackUrl,
      }));
    } else {
      ({ paymentLink } = await generatePaymentLink({
        planPriceId,
        callbackUrl,
      }));
    }

    goToBillingPaymentLinkUrl(paymentLink, t("popUpBlockedMessage"));
  }, [
    paymentFailed,
    planPrices,
    hasPendingPayment,
    t,
    router,
    retryCurrentPeriodPayment,
    generatePaymentLink,
  ]);

  const expirationDateSuffix = subscription?.endDate
    ? new Date(subscription.endDate).getTime()
    : "default";

  const [isExpirationMessageClosed, setIsExpirationMessageClosed] =
    useLocalStorageState(
      `widgets.subscription-banner.expiration.closed.${expirationDateSuffix}`,
      {
        defaultValue: false,
      },
    );

  const [isPaymentSuccessClosed, setIsPaymentSuccessClosed] =
    useLocalStorageState(
      `widgets.subscription-banner.payment.success.closed.${expirationDateSuffix}`,
      {
        defaultValue: false,
      },
    );

  if (!canPerformSecuredApiCalls || !subscriptionSummary || !subscription) {
    return null;
  }

  if (paymentFailed) {
    return (
      <MessageBox className="bg-danger-surface">
        {automaticDebitEnabled
          ? t("paymentFailed.automaticDebit.message")
          : t("paymentFailed.manualPayment.message")}
        &nbsp;
        <button
          onClick={handlePaySubscription}
          className="text-primary-text-high underline"
        >
          {automaticDebitEnabled
            ? t("paymentFailed.automaticDebit.action")
            : t("paymentFailed.manualPayment.action")}
        </button>
      </MessageBox>
    );
  }

  if (latestChargeStatus === "PENDING" || latestChargeStatus === "IN_PROCESS") {
    return (
      <MessageBox className="bg-primary-surface">
        {t("paymentInProcess.message")}&nbsp;
      </MessageBox>
    );
  }

  const handleGoToPlans = () => {
    trackAmplitudeEvent("pdv_all_see_plans_click", {
      from: "trial_banner",
    });
    router.push("/account/plans");
  };

  const handleOpenVideo = () => {
    trackAmplitudeEvent("pdv_banner_action_click", {
      banner_type: "onboarding_training",
      action_type: "watch_video",
    });
    trackAppcuesEvent("pdv_onboarding_watch_video");
  };

  const daysToExpiration = subscription.endDate
    ? differenceInCalendarDays(subscription.endDate, new Date())
    : null;

  const shouldDisplayWelcomeBanner =
    !!daysToExpiration &&
    daysToExpiration > DISPLAY_WELCOME_BANNER_FREE_TRIAL_PERIOD;

  if (
    subscriptionSummary.status === "ACTIVE" &&
    subscription.plan.category === "advanced-free-trial"
  ) {
    const message = shouldDisplayWelcomeBanner
      ? t("welcomeBannerFreeTrial.message")
      : t("activeFreeTrial.message", { days: daysToExpiration });

    const action = shouldDisplayWelcomeBanner
      ? t("welcomeBannerFreeTrial.action")
      : t("activeFreeTrial.action");
    return (
      <MessageBox className="bg-primary-surface">
        {message}
        <button
          className="ml-2 text-primary-text-high underline"
          onClick={
            shouldDisplayWelcomeBanner ? handleOpenVideo : handleGoToPlans
          }
        >
          {action}
        </button>
      </MessageBox>
    );
  }

  if (
    daysToExpiration !== null &&
    daysToExpiration < SUBSCRIPTION_DAYS_UNTIL_EXPIRATION_WARNING &&
    !isExpirationMessageClosed
  ) {
    return (
      <MessageBox
        className="bg-warning-surface"
        onClose={() => setIsExpirationMessageClosed(true)}
      >
        {t("aboutToExpire.message", { days: Math.max(daysToExpiration, 0) })}
        &nbsp;
        <button
          onClick={handlePaySubscription}
          className="text-primary-text-high underline"
        >
          {t("aboutToExpire.action")}
        </button>
      </MessageBox>
    );
  }

  if (
    latestChargeStatus === "PAID" &&
    subscription.endDate &&
    !isPaymentSuccessClosed
  ) {
    const untilDate = format(new Date(subscription.endDate), "dd/MM/yyyy");

    return (
      <MessageBox
        className="bg-success-surface"
        onClose={() => setIsPaymentSuccessClosed(true)}
      >
        {t("paymentSuccess.message", { expirationDate: untilDate })}
        &nbsp;
      </MessageBox>
    );
  }

  return null;
}

function MessageBox({
  children,
  className,
  onClose,
}: {
  children: ReactNode;
  className?: string;
  onClose?: () => void;
}) {
  return (
    <div
      className={clsx(
        "relative p-2 px-6 text-center text-sm sm:p-4 sm:text-lg",
        className,
      )}
    >
      {onClose && (
        <button className="absolute bottom-0 right-3 top-0" onClick={onClose}>
          <CloseIcon className="h2 w2 h-5 w-5" />
        </button>
      )}
      {children}
    </div>
  );
}
