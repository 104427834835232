import { NextResponse } from "next/server";

import { HttpHeader } from "@offline/constants/enums";

export default function createBadRequestResponse(message: string) {
  const body = JSON.stringify({ errors: [{ message }] });

  return new NextResponse(body, {
    status: 400,
    headers: {
      [HttpHeader.CONTENT_TYPE]: "application/json",
    },
  });
}
