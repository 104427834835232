import { EyeOffIcon } from "@nimbus-ds/icons";
import { useTranslations } from "next-intl";

import { Divider, Tag, Thumbnail } from "~/components";

type Props = {
  productName: string;
  imageUrl?: string;
};

export default function UnpublishedItemsInCartRow({
  productName,
  imageUrl,
}: Readonly<Props>) {
  const t = useTranslations("widgets.unpublished-items-in-cart-modal");

  return (
    <>
      <div className="flex gap-4">
        <div className="relative self-center">
          <Thumbnail src={imageUrl} alt={productName} width="80px" />
        </div>

        <div className="flex flex-1 flex-col gap-2 text-base">
          <div>
            <p className="font-medium">{productName}</p>
          </div>
          <Tag appearance="warning">
            <div className="flex items-center gap-1">
              <EyeOffIcon size={12} />
              {t("itemLabel")}
            </div>
          </Tag>
        </div>
      </div>

      <Divider />
    </>
  );
}
