"use client";

import { useCallback, useState } from "react";
import { map } from "lodash";
import { useTranslations } from "next-intl";

import { Button, ConfirmModal, PricingPlanCard, useToast } from "~/components";
import { AMPLITUDE_EVENT_PLAN_PERIOD_NAME } from "~/constants";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import getBillingCheckoutCallbackUrl from "~/helpers/getBillingCheckoutCallbackUrl";
import getCurrencyInstance from "~/helpers/getCurrencyInstance";
import goToBillingPaymentLinkUrl from "~/helpers/goToBillingPaymentLinkUrl";
import isMissingFullAccessRoleError from "~/helpers/isMissingFullAccessRoleError";
import { useRouter } from "~/navigation";
import { appTRPC } from "~/providers/TRPCProvider";
import {
  type Plan,
  type PlanPrice,
  type PlanSubscriptionSummary,
} from "~/types";

export interface PricingPlanFeature {
  label: string;
  enabled: boolean;
}

type FeatureId =
  | "applyDiscount"
  | "registerCustomer"
  | "searchCustomer"
  | "manualShipping"
  | "saleWithoutStock"
  | "registerPaymentMethod"
  | "createProduct"
  | "saveCart"
  | "keyboardShortcuts"
  | "scanProducts"
  | "sellerAttribution"
  | "cashRegister";

interface Feature {
  id: FeatureId;
  enabled: boolean;
}
type PlanCategory = Plan["category"];

const basicFeatures: Feature[] = [
  {
    id: "applyDiscount",
    enabled: true,
  },
  {
    id: "registerCustomer",
    enabled: true,
  },
  {
    id: "searchCustomer",
    enabled: true,
  },
  {
    id: "manualShipping",
    enabled: true,
  },
  {
    id: "saleWithoutStock",
    enabled: false,
  },
  {
    id: "registerPaymentMethod",
    enabled: false,
  },
  {
    id: "scanProducts",
    enabled: false,
  },
  {
    id: "cashRegister",
    enabled: false,
  },
  {
    id: "sellerAttribution",
    enabled: false,
  },
  {
    id: "createProduct",
    enabled: false,
  },
  {
    id: "saveCart",
    enabled: false,
  },
  {
    id: "keyboardShortcuts",
    enabled: false,
  },
];

const advancedFeatures: Feature[] = [
  {
    id: "applyDiscount",
    enabled: true,
  },
  {
    id: "registerCustomer",
    enabled: true,
  },
  {
    id: "searchCustomer",
    enabled: true,
  },
  {
    id: "manualShipping",
    enabled: true,
  },
  {
    id: "saleWithoutStock",
    enabled: true,
  },
  {
    id: "registerPaymentMethod",
    enabled: true,
  },
  {
    id: "scanProducts",
    enabled: true,
  },
  {
    id: "cashRegister",
    enabled: true,
  },
  {
    id: "sellerAttribution",
    enabled: true,
  },
  {
    id: "createProduct",
    enabled: true,
  },
  {
    id: "saveCart",
    enabled: true,
  },
  {
    id: "keyboardShortcuts",
    enabled: true,
  },
];

const entrepreneurFeatures: Feature[] = [
  {
    id: "applyDiscount",
    enabled: true,
  },
  {
    id: "registerCustomer",
    enabled: true,
  },
  {
    id: "searchCustomer",
    enabled: true,
  },
  {
    id: "manualShipping",
    enabled: true,
  },
  {
    id: "saleWithoutStock",
    enabled: true,
  },
  {
    id: "registerPaymentMethod",
    enabled: true,
  },
  {
    id: "scanProducts",
    enabled: true,
  },
  {
    id: "cashRegister",
    enabled: true,
  },
  {
    id: "sellerAttribution",
    enabled: true,
  },
  {
    id: "createProduct",
    enabled: false,
  },
  {
    id: "saveCart",
    enabled: false,
  },
  {
    id: "keyboardShortcuts",
    enabled: false,
  },
];

const DAYS_IN_A_MONTH = 30;

export const PLAN_FEATURES: Record<PlanCategory, Feature[]> = {
  basic: basicFeatures,
  advanced: advancedFeatures,
  "advanced-free-trial": advancedFeatures,
  entrepreneur: entrepreneurFeatures,
};

interface Props {
  plan: Plan;
  planPrice: PlanPrice;
  basePriceAmount?: number;
  summary: PlanSubscriptionSummary;
  isSelected?: boolean;
  isFeaturedPlan?: boolean;
  onPlanSelected?: (planId: string) => void;
  onShowPlansClick?: () => void;
}

export default function PricingPlanCardWidget({
  plan,
  planPrice,
  basePriceAmount,
  summary,
  isSelected = false,
  isFeaturedPlan = false,
  onPlanSelected,
  onShowPlansClick,
}: Readonly<Props>) {
  const t = useTranslations("widgets.pricing-plans");

  const { addToast } = useToast();

  const trpcUtils = appTRPC.useUtils();
  const router = useRouter();

  const { mutateAsync: selectPlan, isPending: selectPlanLoading } =
    appTRPC.planSubscriptions.selectPlan.useMutation();

  const [downgradeConfirmModalOpen, setDowngradeConfirmModalOpen] =
    useState(false);

  const submitSelectedPlan = useCallback(
    async (planId: string) => {
      try {
        if (onPlanSelected) {
          onPlanSelected(planId);
        }

        const { paymentLink } = await selectPlan({
          planId,
          planPriceId: planPrice.id,
          callbackUrl: getBillingCheckoutCallbackUrl(),
        });

        if (paymentLink) {
          goToBillingPaymentLinkUrl(paymentLink, t("popUpBlockedMessage"));
        }

        await trpcUtils.planSubscriptions.getSummary.invalidate();
        await trpcUtils.store.getStoreInfo.invalidate();
      } catch (error) {
        if (isMissingFullAccessRoleError(error)) {
          router.push("/errors/subscription-forbidden");
          return;
        }

        addToast({
          id: "error-toast",
          text: t("errorToast"),
          type: "danger",
          duration: 8000,
        });
      } finally {
        setDowngradeConfirmModalOpen(false);
      }
    },
    [
      onPlanSelected,
      selectPlan,
      planPrice.id,
      trpcUtils.planSubscriptions.getSummary,
      trpcUtils.store.getStoreInfo,
      addToast,
      t,
      router,
    ],
  );

  const handleSelectPlan = useCallback(
    async (planId: string, category: PlanCategory) => {
      const isDowngrading =
        category === "basic" && summary?.status !== "NOT_STARTED";

      if (isDowngrading) {
        setDowngradeConfirmModalOpen(true);
        return;
      }

      await submitSelectedPlan(planId);

      trackAmplitudeEvent("pdv_plans_select", {
        current_plan: summary?.subscription?.plan?.category,
        target_plan: category,
        plan_change_type: "upgrade",
        payment_period: planPrice.periodInDays
          ? AMPLITUDE_EVENT_PLAN_PERIOD_NAME[planPrice.periodInDays]
          : undefined,
      });
    },
    [
      submitSelectedPlan,
      planPrice.periodInDays,
      summary?.status,
      summary?.subscription?.plan?.category,
    ],
  );

  const handleDowngradeModalDismiss = (category: PlanCategory) => {
    setDowngradeConfirmModalOpen(false);

    trackAmplitudeEvent("pdv_plans_select", {
      current_plan: summary?.subscription?.plan?.category,
      target_plan: category,
      plan_change_type: "downgrade",
      is_confirmed: false,
    });
  };

  const handleDowngradeModalConfirm = (id: string, category: PlanCategory) => {
    submitSelectedPlan(id);

    trackAmplitudeEvent("pdv_plans_select", {
      current_plan: summary?.subscription?.plan?.category,
      target_plan: category,
      plan_change_type: "downgrade",
      is_confirmed: true,
    });
  };

  const { status, subscription } = summary || {};

  const { id, category } = plan;
  const { priceCurrency, priceAmount, cpt, periodInDays } = planPrice || {};

  const features = map(PLAN_FEATURES[category], (feature) => {
    return {
      enabled: feature.enabled,
      label: t(`features.${feature.id}`),
    };
  });

  const currentPlanCategory = subscription?.plan.category;
  const hasNonBasicPlan =
    !!currentPlanCategory && currentPlanCategory !== "basic";

  const planName = t(`plansName.${category}`);
  const startWithPlanText = t("startWithPlanButton", { planName });
  const changePlanText =
    category === "basic"
      ? t("downgradePlanButton")
      : t("upgradePlanButton", { period: periodInDays });

  const buttonText =
    status === "NOT_STARTED" ? startWithPlanText : changePlanText;

  const freeTrialModeButtonText =
    category === "basic"
      ? t("chooseBasicPlanButton")
      : t("upgradePlanButton", { period: periodInDays });

  const currentPlan = subscription?.planId === id;
  const isRecommended =
    currentPlanCategory === "advanced-free-trial" &&
    category === "advanced" &&
    !isFeaturedPlan;

  const tag = currentPlan
    ? t("currentPlan")
    : isRecommended
    ? t("recommended")
    : undefined;

  // NOSONAR TODO: Calculate using big.js
  const monthlySubscriptionPriceText = t("subscriptionCost", {
    amount: priceAmount,
    formattedAmount: getCurrencyInstance(
      (Number(priceAmount) / Number(periodInDays)) * DAYS_IN_A_MONTH,
      priceCurrency,
    ).format({ extended: true, precision: 0 }),
  });

  const cptText = t("costPerTransaction", {
    cptPercentage: parseFloat(cpt) * 100,
  });

  const dailyBasePrice = Number(basePriceAmount) / DAYS_IN_A_MONTH;

  const isPromotionalPrice =
    basePriceAmount &&
    dailyBasePrice > Number(priceAmount) / Number(periodInDays);

  const basePriceText = isPromotionalPrice
    ? getCurrencyInstance(basePriceAmount, priceCurrency).format({
        extended: true,
        precision: 0,
      })
    : undefined;

  const footer = !currentPlan ? (
    <div className="flex w-full flex-col items-center gap-4">
      <Button
        className="w-full"
        onClick={() => handleSelectPlan(id, category)}
        loading={selectPlanLoading && isSelected}
        variant={category === "basic" ? "neutral" : "primary"}
      >
        {currentPlanCategory === "advanced-free-trial"
          ? freeTrialModeButtonText
          : buttonText}
      </Button>
      {isFeaturedPlan && (
        <button
          className="text-lg text-primary-interactive underline"
          onClick={onShowPlansClick}
        >
          {t("checkOtherPlans")}
        </button>
      )}
    </div>
  ) : (
    <div className="h-14" />
  );

  return (
    <div className="flex flex-1">
      <PricingPlanCard
        highlight={isRecommended}
        key={category}
        header={t("plansHeader")}
        titlePrefix={t("plansTitlePrefix")}
        category={planName}
        subtitle={monthlySubscriptionPriceText}
        description={cptText}
        features={features}
        basePrice={basePriceText}
        tag={tag}
        footer={footer}
      />
      {category === "basic" && hasNonBasicPlan && (
        <ConfirmModal
          open={downgradeConfirmModalOpen}
          onDismiss={() => handleDowngradeModalDismiss(category)}
          onConfirm={() => handleDowngradeModalConfirm(id, category)}
          title={t(`downgradeConfirmModal.${currentPlanCategory}.title`)}
          description={t(
            `downgradeConfirmModal.${currentPlanCategory}.description`,
          )}
          dismissButtonText={t(
            `downgradeConfirmModal.${currentPlanCategory}.dismissButtonText`,
          )}
          confirmButtonText={t(
            `downgradeConfirmModal.${currentPlanCategory}.confirmButtonText`,
          )}
          loading={selectPlanLoading}
        />
      )}
    </div>
  );
}
