import Link from "next/link";
import { useTranslations } from "next-intl";

import { FullscreenErrorMessage } from "~/components";

type Props = { storeUrl: string };

export default function MultiCDNotSupported({ storeUrl }: Props) {
  const t = useTranslations("widgets.multi-cd-not-supported");

  return (
    <FullscreenErrorMessage title={t("title")} description={t("description")}>
      <Link
        className="text-primary-interactive underline hover:text-primary-interactive-hover"
        href={`${storeUrl}/admin/`}
        title={t("adminLink")}
      >
        {t("adminLink")}
      </Link>
    </FullscreenErrorMessage>
  );
}
