import { type CountryCode } from "./store";

export const TRIAL_DURATION_IN_DAYS = 14;
export const SUBSCRIPTION_DAYS_UNTIL_BLOCK = 10;
export const SUBSCRIPTION_DAYS_UNTIL_EXPIRATION_WARNING = 5;
export const DISPLAY_WELCOME_BANNER_FREE_TRIAL_PERIOD = 4;

export const BILLING_CONCEPT_CODE = "plan-offline-cost" as const;

export const PLAN_CATEGORIES = [
  "basic",
  "advanced",
  "advanced-free-trial",
  "entrepreneur",
] as const;

export const PLAN_NAMES: Record<
  CountryCode,
  Record<"basic" | "advanced" | "advanced-free-trial" | "entrepreneur", string>
> = {
  AR: {
    basic: "Básico",
    advanced: "Avanzado",
    "advanced-free-trial": "Avanzado - Test Gratuito",
    entrepreneur: "Emprendedor",
  },
  BR: {
    basic: "Básico",
    advanced: "Avançado",
    "advanced-free-trial": "Avançado - Teste Gratuito",
    entrepreneur: "Empreendedor",
  },
  CL: {
    basic: "Básico",
    advanced: "Avanzado",
    "advanced-free-trial": "Avanzado - Test Gratuito",
    entrepreneur: "Emprendedor",
  },
  CO: {
    basic: "Básico",
    advanced: "Avanzado",
    "advanced-free-trial": "Avanzado - Test Gratuito",
    entrepreneur: "Emprendedor",
  },
  MX: {
    basic: "Básico",
    advanced: "Avanzado",
    "advanced-free-trial": "Avanzado - Test Gratuito",
    entrepreneur: "Emprendedor",
  },
};
