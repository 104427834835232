import { find } from "lodash";

import { type Product, type ProductVariant } from "~/types";

type ProductPartial = Pick<Product, "images">;
type VariantPartial = Pick<ProductVariant, "image_id">;

export default function getVariantImageUrl(
  product: ProductPartial,
  variant: VariantPartial,
) {
  const defaultImage = product.images[0]?.src;

  if (!variant || !variant.image_id) {
    return defaultImage;
  }

  const image = find<Product["images"][number]>(product.images, {
    id: variant.image_id,
  });

  return image?.src || defaultImage;
}
