import { type ReactNode } from "react";

import {
  Alert,
  ConfirmModal,
  Divider,
  type ConfirmModalProps,
} from "~/components";

type Props = {
  error: boolean;
  errorTitle?: string;
  errorText?: string;
  children: ReactNode;
} & ConfirmModalProps;

export default function CartItemConflictModal({
  children,
  error,
  errorTitle,
  errorText,
  ...props
}: Props) {
  return (
    <ConfirmModal {...props}>
      {error && (
        <Alert title={errorTitle} appearance="danger">
          {errorText}
        </Alert>
      )}

      <div className="mb-6 flex max-h-[240px] flex-1">
        <div className="flex flex-1 flex-col gap-3 overflow-y-auto">
          <Divider />
          {children}
        </div>
      </div>
    </ConfirmModal>
  );
}
