import currencyJS, { type Any, type Options } from "currency.js";

import {
  EXTENDED_SYMBOL_BY_CURRENCY_CODE,
  SYMBOL_BY_CURRENCY_CODE,
  type ISOCurrencyCode,
} from "~/constants";

type ExtendedFormatOptions = Options & { extended?: boolean };

interface ExtendedCurrency extends currencyJS {
  format(opts?: ExtendedFormatOptions): string;
}

export default function getCurrencyInstance(
  value: Any,
  currencyCode: ISOCurrencyCode,
  options: ExtendedFormatOptions = {},
) {
  const defaultOptions = {
    ...options,
    pattern: "!#",
    symbol: SYMBOL_BY_CURRENCY_CODE[currencyCode],
  };

  const currencyInstance = currencyJS(value, {
    ...defaultOptions,
    format: (
      currency?: currencyJS,
      opts: ExtendedFormatOptions = {},
    ): string => {
      const { extended, precision, ...restOpts } = opts;

      const extendedOptions = extended
        ? {
            symbol: EXTENDED_SYMBOL_BY_CURRENCY_CODE[currencyCode],
            pattern: currencyCode === "MXN" ? "!# MXN" : "!#",
          }
        : {};

      return currencyJS(currency || 0, { precision }).format({
        ...restOpts,
        ...extendedOptions,
        // NOTE: This was requested explicitly by the product team
        separator: ["MXN", "USD"].includes(currencyCode) ? "," : ".",
        decimal: ["MXN", "USD"].includes(currencyCode) ? "." : ",",
      });
    },
  });

  return currencyInstance as ExtendedCurrency;
}
