import { NextResponse } from "next/server";
import { type z } from "zod";

import { HttpHeader } from "@offline/constants/enums";

export default function createBadRequestZodResponse(err: z.ZodError) {
  const body = JSON.stringify({ errors: err.issues });
  return new NextResponse(body, {
    status: 400,
    headers: { [HttpHeader.CONTENT_TYPE]: "application/json" },
  });
}
