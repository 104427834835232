"use client";

import { useState } from "react";
import { orderBy } from "lodash";
import { useTranslations } from "next-intl";

import FullscreenModal from "~/components/FullscreenModal";
import FullscreenModalFooter from "~/components/FullscreenModal/FullscreenModalFooter";
import { Button, Checkbox, Select, SelectField, useToast } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { getLocaleFieldValue } from "~/helpers/i18n";
import { useCheckoutStore } from "~/hooks";
import useSelectedLocation from "~/hooks/useSelectedLocation";
import { usePathname, useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";

type Props = {
  onAfterModalLeave?: () => void;
};

function SelectLocationModalWidget({ onAfterModalLeave }: Readonly<Props>) {
  const pathname = usePathname();
  const router = useRouter();
  const t = useTranslations("widgets.select-location-modal");
  const storeInfo = useStoreInfo();
  const { adminLanguage, country, locations } = storeInfo;
  const { addToast } = useToast();

  const {
    saveLocations,
    hasValidOriginLocationSelected,
    selectedOriginLocationId: defaultSelectedOriginLocationId,
    selectedFulfillmentLocationId: defaultSelectedFulfillmentLocationId,
  } = useSelectedLocation();

  const { reset: resetCheckout } = useCheckoutStore();

  const [open, setOpen] = useState(true);
  const [selectedOriginLocationId, setSelectedOriginLocationId] = useState(
    defaultSelectedOriginLocationId,
  );

  const [selectedFulfillmentLocationId, setSelectedFulfillmentLocationId] =
    useState(defaultSelectedFulfillmentLocationId);

  const [hasOtherFulfillmentLocation, setHasOtherFulfillmentLocation] =
    useState(
      !!defaultSelectedOriginLocationId &&
        !!defaultSelectedFulfillmentLocationId &&
        defaultSelectedOriginLocationId !==
          defaultSelectedFulfillmentLocationId,
    );

  const isEditMode = hasValidOriginLocationSelected;
  const locale = `${adminLanguage}_${country}`;

  const originLocationChanged =
    defaultSelectedOriginLocationId !== selectedOriginLocationId;

  const fulfillmentLocationChanged =
    defaultSelectedFulfillmentLocationId !== selectedFulfillmentLocationId;

  const handleSaveButtonClick = () => {
    saveLocations({
      originLocationId: selectedOriginLocationId,
      fulfillmentLocationId: selectedFulfillmentLocationId,
    });

    addToast({
      id: "select-location-toast",
      text: t(`toast.${isEditMode ? "edit" : "add"}`),
      type: "success",
      duration: 4000,
    });

    if (fulfillmentLocationChanged) {
      resetCheckout();
    }

    setOpen(false);

    if (originLocationChanged || fulfillmentLocationChanged) {
      let changeType =
        originLocationChanged && fulfillmentLocationChanged
          ? "both"
          : undefined;

      if (!changeType) {
        changeType = originLocationChanged ? "origin" : "inventory";
      }

      trackAmplitudeEvent("pdv_location_change", {
        location_change_type: changeType,
        use_origin_as_inventory: !hasOtherFulfillmentLocation,
      });

      if (pathname.includes("/registers/")) {
        // Redirects the user to the root segment when the current URL is scoped
        // by an ID that might depend on a location.
        // This prevents displaying data from a location that is not the selected one.
        // e.g. /registers/{id}/active -> /registers
        router.replace(`/registers`);
      }
    }
  };

  return (
    <FullscreenModal
      open={open}
      title={t("title")}
      description={t("description")}
      closable={isEditMode}
      onAfterModalLeave={onAfterModalLeave}
    >
      <div className="flex w-full flex-col sm:w-[600px]">
        <div className="flex flex-col gap-4">
          <SelectField
            id="origin-location"
            name="origin-location"
            label={t("originLocationSelect.label")}
            value={selectedOriginLocationId ?? "empty"}
            onChange={({ target: { value } }) => {
              setSelectedOriginLocationId(value);
              setSelectedFulfillmentLocationId(
                hasOtherFulfillmentLocation ? undefined : value,
              );
            }}
          >
            <Select.Option
              disabled
              label={t("originLocationSelect.emptyItemLabel")}
              value="empty"
            />
            {orderBy(locations, ["createdAt"]).map(({ id, name }) => (
              <Select.Option
                key={id}
                value={id}
                label={getLocaleFieldValue(name, locale)}
              />
            ))}
          </SelectField>

          <div className="mt-2">
            <Checkbox
              name="has-other-fulfillment-location"
              label={t("otherFulfillmentLocationCheckbox")}
              defaultChecked={hasOtherFulfillmentLocation}
              disabled={!selectedOriginLocationId}
              onChange={({ target: { checked } }) => {
                setHasOtherFulfillmentLocation(checked);
                setSelectedFulfillmentLocationId(
                  checked ? undefined : selectedOriginLocationId,
                );
              }}
            />
          </div>

          {hasOtherFulfillmentLocation && (
            <SelectField
              id="fulfillment-location"
              name="fulfillment-location"
              label={t("fulfillmentLocationSelect.label")}
              value={selectedFulfillmentLocationId ?? "empty"}
              onChange={({ target: { value } }) =>
                setSelectedFulfillmentLocationId(value)
              }
            >
              <Select.Option
                disabled
                label={t("fulfillmentLocationSelect.emptyItemLabel")}
                value="empty"
              />
              {orderBy(locations, ["createdAt"])
                .filter(({ id }) => id !== selectedOriginLocationId)
                .map(({ id, name }) => (
                  <Select.Option
                    key={id}
                    value={id}
                    label={getLocaleFieldValue(name, locale)}
                  />
                ))}
            </SelectField>
          )}
        </div>
      </div>

      <FullscreenModalFooter>
        {isEditMode && (
          <Button
            className="flex-1"
            variant="neutral"
            onClick={() => setOpen(false)}
          >
            {t("buttons.cancel")}
          </Button>
        )}
        <Button
          className="flex-1"
          disabled={
            !selectedOriginLocationId ||
            !selectedFulfillmentLocationId ||
            (!originLocationChanged && !fulfillmentLocationChanged)
          }
          onClick={handleSaveButtonClick}
        >
          {t("buttons.save")}
        </Button>
      </FullscreenModalFooter>
    </FullscreenModal>
  );
}

export default SelectLocationModalWidget;
