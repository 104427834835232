"use client";

import React, { createContext, useContext } from "react";
import type { ReactNode } from "react";
import ms from "ms";

import { FullscreenSpinner } from "~/components";
import { FullscreenStoreInfoError } from "~/hocs/withAccessControlAndStoreInfo/components";
import FullscreenStoreDownError from "~/hocs/withAccessControlAndStoreInfo/components/FullscreenStoreDownError";
import { appTRPC } from "~/providers/TRPCProvider";
import { type StoreInfo } from "~/types";

const StoreInfoContext = createContext<StoreInfo | undefined>(undefined);

const useStoreInfo = () => {
  const ctx = useContext(StoreInfoContext);

  if (!ctx) {
    throw new Error("Missing store info");
  }

  return ctx;
};

interface Props {
  children: ReactNode;
  initialStoreInfo?: StoreInfo;
}

function StoreInfoProvider({ children, initialStoreInfo }: Readonly<Props>) {
  const { data, isPending, isError } = appTRPC.store.getStoreInfo.useQuery(
    undefined,
    {
      initialData: initialStoreInfo,
      staleTime: ms("5 seconds"),
    },
  );

  if (isPending) {
    return <FullscreenSpinner />;
  }

  if (isError && !data) {
    return <FullscreenStoreInfoError />;
  }

  const { isStoreDown } = data;

  if (isStoreDown) {
    return <FullscreenStoreDownError storeInfo={data} />;
  }

  return (
    <StoreInfoContext.Provider value={data}>
      {children}
    </StoreInfoContext.Provider>
  );
}

export { StoreInfoProvider, useStoreInfo };
