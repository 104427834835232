import { get, map } from "lodash";

import { type ProductVariant } from "~/types";

type VariantPartial = Pick<ProductVariant, "values">;

export default function getVariantNames(v: VariantPartial, locale: string) {
  return map(v.values, (value) => {
    return get(value, locale) || get(value, "pt") || get(value, "es");
  }).join(" / ");
}
