"use client";

import { type ComponentProps } from "react";
import { type IconProps } from "@nimbus-ds/icons";
import clsx from "clsx";

import { IconBox, Tooltip } from "~/components";
import useIsSmallScreen from "~/hooks/useIsSmallScreen";
import { Link, usePathname } from "~/navigation";

interface Props extends ComponentProps<typeof Link> {
  href: string;
  title: string;
  icon: React.FC<IconProps>;
}

export default function SidebarLinkButton({
  title,
  icon: Icon,
  ...linkProps
}: Readonly<Props>) {
  const pathname = usePathname();
  const isSmallScreen = useIsSmallScreen();

  const Item = (
    <IconBox
      icon={<Icon size={24} />}
      className={clsx(!pathname.startsWith(linkProps.href) && "bg-transparent")}
    />
  );

  return (
    <Link {...linkProps}>
      {isSmallScreen ? (
        Item
      ) : (
        <Tooltip content={title} position="right">
          {Item}
        </Tooltip>
      )}
    </Link>
  );
}
