import { Fragment } from "react";
import { useTranslations } from "next-intl";

import KeyIcon from "./KeyIcon";

type Props = {
  description: string;
  keys: string[];
  withRangeSeparator?: boolean;
};

const TableRow = ({ description, keys, withRangeSeparator }: Props) => {
  const t = useTranslations("widgets.shortcut-list-modal");

  return (
    <tr>
      <td className="flex h-12 items-center justify-between border-b p-4">
        <p className="text-sm font-normal">{description}</p>
        <div className="flex gap-2">
          {keys.map((key, index) => (
            <Fragment key={key}>
              <KeyIcon value={key} />
              {withRangeSeparator && index === 0 && (
                <p className="my-auto text-sm font-normal">{t("to")}</p>
              )}
            </Fragment>
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
