import { merge } from "lodash";

export default async function getMessagesByLocale(
  locale: string,
  defaultLocale = "es",
) {
  const userMessages = (await import(`~/../messages/${locale}.json`)).default;
  const defaultMessages = (await import(`~/../messages/${defaultLocale}.json`))
    .default;
  return merge({}, defaultMessages, userMessages);
}
