import React, { useMemo } from "react";
import { UserCircleIcon } from "@nimbus-ds/icons";

interface Props {
  firstName?: string;
  lastName?: string;
}

function UserNameInitials({ firstName = "", lastName = "" }: Readonly<Props>) {
  const initials = useMemo(() => {
    const firstNameInitials = firstName.split(" ").map((w) => w[0] || "");
    const lastNameInitials = lastName.split(" ").map((w) => w[0] || "");

    const firstInitial = firstNameInitials.shift() || lastNameInitials.shift();

    if (!firstInitial) {
      return undefined;
    }

    const secondInitial = lastNameInitials.shift() || firstNameInitials.shift();

    if (!secondInitial) {
      return undefined;
    }

    return `${firstInitial}${secondInitial}`.toUpperCase();
  }, [firstName, lastName]);

  return (
    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-surface text-base font-medium leading-5 text-primary-text-low">
      {!initials ? <UserCircleIcon width={24} height={24} /> : initials}
    </div>
  );
}

export default UserNameInitials;
