import { type ComponentProps } from "react";
import { useLocale, useTranslations } from "next-intl";

import CartItemConflictModal from "~/components/CartItemConflictModal";
import { getProductName, getVariantImageUrl } from "~/helpers";
import { type CartItem } from "~/hooks";
import UnpublishedItemsInCartRow from "./UnpublishedItemsInCartRow";

type CartItemConflictModalProps = ComponentProps<typeof CartItemConflictModal>;

type Props = {
  items: CartItem[];
  error: boolean;
} & Omit<
  CartItemConflictModalProps,
  | "children"
  | "title"
  | "description"
  | "dismissButtonText"
  | "confirmButtonText"
  | "renderCartItem"
>;

export default function UnpublishedItemsInCartModal(props: Props) {
  const t = useTranslations("widgets.unpublished-items-in-cart-modal");
  const locale = useLocale();

  return (
    <CartItemConflictModal
      {...props}
      title={t("title")}
      description={t("description")}
      dismissButtonText={t("editCartButton")}
      confirmButtonText={t("confirmButton")}
      errorTitle={t("error.title")}
      errorText={t("error.description")}
    >
      {props.items.map(({ product, variant }) => {
        const productName = getProductName(product, locale);
        const imageUrl = getVariantImageUrl(product, variant);

        return (
          <UnpublishedItemsInCartRow
            key={variant.id}
            productName={productName}
            imageUrl={imageUrl}
          />
        );
      })}
    </CartItemConflictModal>
  );
}
