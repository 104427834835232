import { get } from "lodash";

import { type I18nField } from "@offline/tiendanube-api-client";

export default function getLocaleFieldValue(
  field: I18nField | undefined,
  locale: string,
): string {
  return (
    get(field, locale) ||
    get(field, "pt") ||
    get(field, "es") ||
    get(field, "es_mx") ||
    get(field, "en", "")
  );
}
