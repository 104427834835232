import { get } from "lodash";

import { type Product } from "~/types";

type ProductPartial = Pick<Product, "name">;

export default function getProductName(p: ProductPartial, locale: string) {
  return (
    get(p, `name.${locale}`) || get(p, "name.pt") || get(p, "name.es") || ""
  );
}
