"use client";

import {
  CogIcon,
  MenuIcon,
  ShoppingCartIcon,
  StatsIcon,
  TiendanubeIcon,
  WalletIcon,
} from "@nimbus-ds/icons";
import clsx from "clsx";
import { useTranslations } from "next-intl";

import { useModalContext } from "~/app/[locale]/(components)/ModalProvider";
import { Divider } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { useUIStore } from "~/hooks";
import useIsSmallScreen from "~/hooks/useIsSmallScreen";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import SidebarLinkButton from "./SidebarLinkButton";

type AppItem = "cart" | "cashRegister" | "statistics" | "settings";

const RoutesByAppItem: Record<AppItem, string> = {
  cart: "/cart",
  cashRegister: "/registers",
  statistics: "/stats",
  settings: "/settings",
};

export default function SidebarWidget() {
  const t = useTranslations("widgets.sidebar");

  const storeInfo = useStoreInfo();
  const { features, userConfig } = storeInfo || {};

  const showStatistics = userConfig?.hasStatisticsAccess;

  const { openModal: openUpsellCashRegisterModal } = useModalContext(
    "upsell-cash-register",
  );

  const isSmallScreen = useIsSmallScreen();
  const { showSmallScreenDrawer } = useUIStore();

  const handleCashRegisterClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!features?.cashRegister) {
      e.preventDefault();

      trackAmplitudeEvent("pdv_upsell_prompt_view", {
        prompt_type: "friction_cash_register",
      });

      openUpsellCashRegisterModal();
    } else {
      trackAmplitudeEvent("pdv_navbar_register_click");
    }
  };

  return (
    <div
      className={clsx(
        "fixed bottom-0 flex h-12 w-full items-center justify-around gap-4 border-t border-neutral-surface-highlight bg-neutral-background p-2",
        "sm:relative sm:h-auto sm:w-auto sm:flex-shrink-0 sm:flex-col sm:border-r sm:border-t-0 sm:p-4",
      )}
    >
      {!isSmallScreen && <TiendanubeIcon size={40} />}

      <SidebarLinkButton
        href={RoutesByAppItem.cart}
        title={t("cart")}
        icon={ShoppingCartIcon}
      />

      {!isSmallScreen && (
        <SidebarLinkButton
          href={RoutesByAppItem.cashRegister}
          title={t("cashRegister")}
          icon={WalletIcon}
          onClick={handleCashRegisterClick}
        />
      )}

      {showStatistics && (
        <SidebarLinkButton
          href={RoutesByAppItem.statistics}
          title={t("statistics")}
          icon={StatsIcon}
          onClick={() => trackAmplitudeEvent("pdv_navbar_stats_click")}
        />
      )}

      {!isSmallScreen && (
        <div className="flex flex-1 flex-col justify-end gap-4">
          <Divider />

          <SidebarLinkButton
            href={RoutesByAppItem.settings}
            title={t("settings")}
            icon={CogIcon}
            onClick={() => trackAmplitudeEvent("pdv_navbar_settings_click")}
          />
        </div>
      )}

      {isSmallScreen && (
        <SidebarLinkButton
          href="#"
          title={t("appMenu")}
          icon={MenuIcon}
          onClick={showSmallScreenDrawer}
        />
      )}
    </div>
  );
}
