import clsx from "clsx";

type Props = {
  value: string;
};

const KeyIcon = ({ value }: Props) => (
  <div
    className={clsx(
      "flex h-8 rounded-lg border border-neutral-surface-highlight bg-neutral-surface px-2",
      value.length > 1 ? "w-auto" : "w-8",
    )}
  >
    <p className="m-auto justify-center text-sm font-normal">{value}</p>
  </div>
);

export default KeyIcon;
